import * as React from "react"
import { Link } from "gatsby"

const ToolCard = (props) => {
  return (
    <div className="col s12 m6">
        <div className="card blue-grey darken-1">
        <div className="card-content white-text">
            <span className="card-title">{props.name}</span>
            <p>{props.description}</p>
        </div>
        <div className="card-action">
            <Link target="_blank" to={props.link}>Launch</Link>
        </div>
        </div>
    </div>

  )
}

export default ToolCard;