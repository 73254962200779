import Amplify, { Auth } from 'aws-amplify';

console.log("Reading configuration from ", `../clusterConfig.${process.env.NODE_ENV}.json`);
const configSource = require(`../clusterConfig.${process.env.NODE_ENV}.json`);

Amplify.configure({
    Storage: {
        AWSS3: {
            bucket: 'ikebana-storage-common', //REQUIRED -  Amazon S3 bucket name
            region: 'us-east-1', //OPTIONAL -  Amazon service region
        }
    },
    Auth: {

        region: configSource.AWS_REGION,
        userPoolId: configSource.AUTH_COGNITO_USERPOOL,
        userPoolWebClientId: configSource.AUTH_COGNITO_CLIENTID,
        identityPoolId: configSource.AUTH_COGNITO_IDENTITYPOOL,
        mandatorySignIn: true,

        cookieStorage: {
            domain: configSource.SITE_DOMAIN, //configSource.SITE_DOMAIN,
            path: '/',
            expires: 365,
            sameSite: "lax",
            secure: configSource.SITE_DOMAIN !== 'localhost'
        },

        authenticationFlowType: 'USER_SRP_AUTH', //'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        clientMetadata: { 
            app: configSource.SITE_NAME,
            env: configSource.SITE_ENV
        },

         // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: configSource.AUTH_COGNITO_DOMAIN,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: configSource.AUTH_REDIRECT_SIGNIN,
            redirectSignOut: configSource.AUTH_REDIRECT_SIGNOUT,
            responseType: 'code'
        }
    }
});

const currentConfig = Auth.configure();
export default currentConfig;